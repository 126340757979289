import { Box, styled } from '@mui/system';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import Vimeo from '@u-wave/react-vimeo';
import "./introPage.css";
import { Fade } from '@mui/material';
import {decode} from 'html-entities';

const IntroType = styled('p')(({ theme }) =>({
    color: theme.palette.primary.main,
    fontFamily: theme.typography.primary.fontFamily,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: 'left',
    [theme.breakpoints.up("xs")]: {
        fontSize: 12,
    },
    [theme.breakpoints.up("md")]: {
        fontSize: 18,
    },

}));

export default function IntroPage(props) {
    const [offsetY, setOffsetY] = React.useState(0);
    const [scrolledOut, setScrolledOut] = React.useState(false);
    const [introExited, setIntroExited] = React.useState(false);
    const [posterOffset, setPosterOffset] = React.useState(0);
    const [videoUrl, setVideoUrl] = React.useState("");
    const [posterImage, setPosterImage]   = React.useState({});
    const [description, setDescription]  = React.useState("");
    const [pageReady, setPageReady] = React.useState(false);

    useEffect(() => {
        axios.get('https://barbaraherold.net/wordpress/wp-json/wp/v2/intro?per_page=100')
        .then(res => {
            if(res.data.length) {
                console.log("Res length " + res.data.length);
                setPosterImage(res.data[0].acf.poster_image);
                setDescription(decode(res.data[0].content.rendered));
                setPageReady(true);
            } else {
                if(props.post['patchwork_entries'][0].videoUrl !== "") {
                    console.log("This post has a video");
                    setVideoUrl(props.post['patchwork_entries'][0].videoUrl);
                } else {
                    setPageReady(true);
                    console.log("This post has no video");
                }
            }
        })
        .catch(err => console.log(err));

        const scrollHandler = () => {
            setOffsetY(window.scrollY)
            if(window.scrollY > window.innerHeight*2 && !introExited) { //reached end of window
                // setScrolledOut(true);
                setPosterOffset(-1 * (window.scrollY - window.innerHeight*2));
                if(window.scrollY > (window.innerHeight*3)) { //scrolled the whole thing out of view
                    console.log("TIME TO GO");
                    if(props.onExitView) {
                        props.onExitView();
                    }
                }
            } else {
                setPosterOffset(0);
                setScrolledOut(false);
            }
        }

        window.addEventListener('scroll', scrollHandler);
        // returned function will be called on component unmount 
        return function cleanup() {
            window.removeEventListener('scroll', scrollHandler);
        }
      }, [introExited, props])

      const getBackgroundImage = () => {
          if(posterImage) {
              console.log("Poster image " + posterImage);
              return `url(${posterImage})`;
          } else {
              return props.post ? `url( ` + props.post.patchwork_entries[0].imageUrl + `)` : `url()`;
          }
      }

      const getTitleBarContent = () => {
        if(description) {
            return `${description}`;
        } else {
            return props.post && decode(props.post.title.rendered) + " &bull; " + props.post.acf.year + ", " + props.post.acf.description;
        }
    }

    const handleVimeoReady = (v) => {
        setPageReady(true);
    }

    const handleVimeoLoaded = (v) => {
        
    }

    return(
        <Fade in={pageReady} timeout={5000}>
            <Box sx={[{
                height:'300vh',
            },
                introExited && {
                    display:'none',
                }
            ]}>
                {/* <Box sx={[{
                    width:'100%',
                    height:'100%',
                    backgroundImage: getBackgroundImage(),
                    position: 'absolute',
                    top:'100vh',
                    zIndex:0,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }
                ]} /> */}
                <Box sx={[
                    {
                    width:'100%',
                    height:'100%',
                    backgroundImage: (videoUrl === "") ? getBackgroundImage() : "none",
                    zIndex:0,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position:'fixed',
                    top: posterOffset + 'px',
                    },
                    scrolledOut && {
                        display: 'none',
                    }
                ]}>
                    {videoUrl !== "" && <Vimeo
                        video={videoUrl}
                        className="intro-vimeoPlayer"
                        responsive
                        onReady={handleVimeoReady}
                        onLoaded={handleVimeoLoaded}
                        width={1920}
                        autoplay
                        loop
                        muted
                        controls={false}
                    />}
                </Box>
                <Box sx={{
                    width:'100%',
                    backgroundColor: (theme) => theme.palette.secondary.main ,
                    position:'fixed',
                    top: offsetY / 2 + 'px',
                    zIndex:2,

                }}>
                    <IntroType dangerouslySetInnerHTML={{__html: getTitleBarContent()}}></IntroType>
                </Box>
            </Box>

        </Fade>
    )
}
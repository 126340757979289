import { Typography } from "@mui/material";
import { styled } from "@mui/system";


export const BhBodyType = styled((props) =>(<Typography variant="body2" {...props}>{props.children}</Typography>))(({ theme }) => ({
    '& a': {
        color: theme.palette.primary.main,
    },
    '& a:hover': {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
    }
  }));
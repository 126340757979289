import React, {useEffect} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'
import { Box, styled, ThemeProvider } from '@mui/system';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {Link, useLocation} from 'react-router-dom';

export default function TagNav(props) {

    React.useEffect(() => {
        if(props.tags && props.onTagsReady) {
            props.onTagsReady();
        }
      }, [props]);
    const clickedNavButton = (el, route) => {
        console.log("Clicked navbutton " + el);
        if(props.onClickedNavButton) {
            props.onClickedNavButton(el, route);
        }
    }

    const location = useLocation();

    
    return (
        <ScrollContainer className="scroll-container"
                         vertical="false"
                         style={{
                            height: 50,
                            width: '100%',
                            top: '0',
                            background:'white',
                         }}
        >
        <Box
            sx={{
                height: '100%',
                overflowX: 'visible',
                whiteSpace: 'nowrap',
                display: 'table',
            }}
        >
        <Box sx={{
            display:'table-cell',
            verticalAlign:'middle',
        }}>
            <Button
                    variant="text"
                    onClick={() => clickedNavButton("index", "index")}
                    sx={[{
                        backgroundColor: (theme) => theme.palette.primary.light ,
                        color: (theme) => theme.palette.primary.main,
                        borderRadius: '12px',
                        marginRight: (theme) => theme.spacing(1),
                        marginLeft: (theme) => theme.spacing(1),
                        lineHeight: 1,
                        minWidth: 'unset',
                        border: '2px solid',
                        fontFamily: (theme) => theme.typography.primary.fontFamily,
                        borderColor: (theme) => theme.palette.primary.main,
                        '&:hover': {
                            color: (theme) => theme.palette.primary.main,
                            backgroundColor: (theme) => theme.palette.secondary.main,
                        }
                    },
                    location.pathname === "/index" && {
                        borderColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.primary.main,
                        backgroundColor: (theme) => theme.palette.secondary.main,
                    }]}
            >
                Index
            </Button>
            {
                props.tags.map((el) => (
                    <Button key={el} 
                            variant="text"
                            onClick={() => clickedNavButton(el, "works")}
                            sx={[{
                                backgroundColor: (theme) => theme.palette.primary.main,
                                color: (theme) => theme.palette.primary.light,
                                borderRadius: '12px',
                                marginRight: (theme) => theme.spacing(1),
                                marginLeft: (theme) => theme.spacing(1),
                                lineHeight: 1,
                                minWidth: 'unset',
                                border: '2px solid',
                                borderColor: (theme) => theme.palette.primary.main,
                                fontFamily: (theme) => theme.typography.primary.fontFamily,
                                '&:hover': {
                                    color: (theme) => theme.palette.primary.main,
                                    backgroundColor: (theme) => theme.palette.secondary.main,
                                    borderColor: (theme) => theme.palette.secondary.main,
                                }
                            },
                            props.selected === el && {
                                color: (theme) => theme.palette.primary.main,
                                backgroundColor: (theme) => theme.palette.secondary.main,
                                borderColor: (theme) => theme.palette.secondary.main,
                            }]}
                    >
                        {el}
                    </Button>
                ))
            }
        </Box>
            </Box>
        </ScrollContainer>
    )
}

TagNav.propTypes = {
    tags: PropTypes.array.isRequired,
}
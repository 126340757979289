import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Grid, IconButton, SvgIcon } from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as ArrowIcon } from '../../res/images/pfeil.svg';
import { ReactComponent as CheckboxSvg } from '../../res/images/checkbox.svg';
import { ReactComponent as CheckboxCheckedSvg } from '../../res/images/checkboxausgewaehlt.svg';

export const ExpandIcon = styled((props) => {
  const { align, ...other } = props;
  return <SvgIcon {...other}>
    <ArrowIcon />
  </SvgIcon>;
})(({ theme, align }) => ({

}));

export const CheckboxIcon = styled((props) => {
  const { align, ...other } = props;
  return <SvgIcon {...other}>
    <CheckboxSvg />
  </SvgIcon>;
})(({ theme, align }) => ({

}));

export const CheckboxCheckedIcon = styled((props) => {
  const { align, ...other } = props;
  return <SvgIcon {...other}>
    <CheckboxCheckedSvg />
  </SvgIcon>;
})(({ theme, align }) => ({

}));

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'scale(1,1)' : 'scale(1,-1)',
  padding: theme.spacing(0),
  display: 'inline',
  // marginLeft: 'auto',
  '&:hover': {
    backgroundColor: '#fff0',
  },
  '&:active': {
    backgroundColor: '#fff0',
  },
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BORDER_THICKNESS = 2;

export const Accordion = styled((props) => {
    const { last, ...other } = props;
    return <MuiAccordion disableGutters elevation={0} square {...other} />
  })(({ theme, last }) => ({
    border: 'none',
    borderTop: `${BORDER_THICKNESS}px solid ${theme.palette.primary.main}`,
    borderBottom: last && `${BORDER_THICKNESS}px solid ${theme.palette.primary.main}`,
    '&:before': {
      display: 'none',
    },
  }));
  
  export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ExpandIcon sx={{ fontSize: '3rem',
                                                  
                                          }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === theme.palette.primary.light,
    flexDirection: 'row',
    padding: 0,
    paddingRight: theme.spacing(4),
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'scale(1, 1)',
      color: theme.palette.primary.main,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'scale(1, -1)',
      color: theme.palette.secondary.main,
    },
    '& .MuiAccordionSummary-content': {
      [theme.breakpoints.up('xs')]: {
        marginLeft: theme.spacing(1),
        
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(0),
        
      },
      fontFamily: theme.typography.secondary.fontFamily,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  }));
  
  export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'left',
    marginLeft: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  }));
import {createTheme } from '@mui/material/styles';

const primaryTypo = [
    "BallPill",
    "serif",
].join(',');

const secondaryTypo = [
    "sans-serif",
].join(',');

const highlightColors = [
    '#75FBFD',
    '#75FB4C',
    '#FFFF54',
];

const colorOfToday = Math.floor(Math.random() * highlightColors.length);

const theme = createTheme({
    fixedMargins: {
        top: 15,
    },
    palette: {
        primary: {
            main: '#000',
            light: '#fff',
        },
        secondary: {
            main: highlightColors[colorOfToday],
        }
    },
    typography: {
        primary: {
            fontFamily: primaryTypo,
        },
        secondary: {
            fontFamily: secondaryTypo,
        }
    },
    components: {
        MuiTypography: {
            
            styleOverrides: {
                root: {
                    fontFamily: primaryTypo,
                },
                body1: {
                    fontFamily: primaryTypo,
                },
                body2: {
                    fontFamily: secondaryTypo,
                    fontWeight: 400,
                    
                },
                h3: {
                    fontFamily: secondaryTypo,
                    fontSize: '1.8rem',
                }
                
            }
        }
    }
});

  export default theme;
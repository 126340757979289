import { Link, Stack } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, {useEffect} from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll'

const Thumbnail = styled('img')(({theme}) => ({
    width: '100%',

}))

export default function WorkEntry(props) {

    const clickHandler = () => {
        scroll.scrollToTop();        
    }

    return(
        <Stack sx={{
            maxWidth: '100%',
            textAlign: 'left',
            fontFamily: (theme)=>theme.typography.primary.fontFamily,
            position: 'relative',
        }} spacing={0}>

                <Thumbnail src={props.thumb} />
                <Box className='workEntry-title' sx={{
                    textTransform: 'uppercase',
                    marginLeft: (theme) => [theme.spacing(2), '3px'],
                    marginTop: 1,
                }}>
                    {props.title}
                </Box>
                <Box className='workEntry-content' sx={{
                    marginLeft: (theme) => [theme.spacing(2), '3px'],
                    fontSize: 14,
                    width: ['75%', '100%'],
                }}>
                    {props.year} &bull; {props.content}
                </Box>
            <Link component={RouterLink} onClick={clickHandler} to={props.target || "/"} sx={{
                textDecoration: 'none',
                position: 'absolute',
                width: '100%',
                height: '104%',
                top: '-2%',
                background: (theme) => theme.palette.secondary.main,
                opacity: '0',
                borderRadius: '2%',
                transition: 'opacity 160ms',
                '&:hover': {
                    opacity: 0.7,
                }
            }}>
            </Link>
        </Stack>
    )
}
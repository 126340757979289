import { Button, Collapse, Fade, Grid, Link, Stack, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import Vimeo from '@u-wave/react-vimeo';
import React, {useEffect, useState} from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { ExpandIcon, ExpandMore } from '../utils/Accordion';
import { isURL } from '../utils/Utils';
import { useCookies } from 'react-cookie';
import { COOKIE_PREF_KEY, COOKIE_PREF_VALUE_ALL, COOKIE_PREF_VALUE_ESSENTIAL } from '../cookiePage/cookieConst';
import "./workPage.css"
import { BhBodyType } from '../utils/BhTypography';
import {decode} from 'html-entities';

const WorkImage = styled('img')(({theme}) => ({
  width: '100%',
  maxHeight: '65vh',
  objectFit: ['cover','contain'],
  objectPosition: 'center center',
}))

function VideoItem(props) {
  const [url, setUrl] = React.useState();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [playerWidth, setPlayerWidth] = useState(0);
  const [playerHeight, setPlayerHeight] = useState(0);
  const [calculatedheight, setCalculatedHeight] = useState(60);

  useEffect(() => {
    if(props.url && isURL(props.url)) {
      setUrl(props.url);
    }
    function recalculatePlayerSize() {
      if(playerWidth > 0 && playerHeight > 0) {
        console.log("Orientation is " + (playerWidth / playerHeight > 1 ? "landscape" : "portrait"));
        const isPlayerLandscape = ((playerWidth / playerHeight) > 1);
        const isClientLandscape = ((window.innerWidth / window.innerHeight) > 1);
        if(isPlayerLandscape && isClientLandscape) {
          setCalculatedHeight(60);
        } else if(isPlayerLandscape && !isClientLandscape) { //player landscape, but client is portrait
          setCalculatedHeight(33);
        } else if(!isPlayerLandscape && isClientLandscape) { //player portrait, client landscape
          setCalculatedHeight(60);
        } else { //player portrait, client portrait
          setCalculatedHeight(60);
        }
      }      
    }
    recalculatePlayerSize();
    window.addEventListener('resize', recalculatePlayerSize);

  }, [props.url, playerWidth, playerHeight]);

  const makeYoutubeVideo = () => {

  }

  const handleVimeoReady = (v,e) => {
    v.getVideoWidth().then(width => {
      console.log("Width: " + width);
      setPlayerWidth(width);
    }).catch(err => {
      console.log("VIMEO ERROR: " + err);
    })
    v.getVideoHeight().then(height => {
      console.log("Height: " + height);
      setPlayerHeight(height);
    }).catch(err => {
      console.log("VIMEO ERROR: " + err);
    });
  }

  const makeVimeoVideo = () => {
    console.log("Make vimeo  " + url);
    return(
      url && <Vimeo
        video={url}
        className="vimeoPlayer"
        responsive
        onReady={handleVimeoReady}
        onLoaded={handleVimeoReady}
      />
    )
  }

  const makeCookieBox = () => {
    console.log("Missing video cookie");
    return(
      <Box sx={{
        width: '100%',
        height: '30vh',
        background: 'white',
        textAlign: 'center',
        backgroundColor: (theme) => theme.palette.secondary.main,
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>Please <Button onClick={() => removeCookie("cookiePref")} variant="text">accept</Button> all the cookies!</Box>
    )
  }

  const getBoxContent = () => {
    if(cookies.cookiePref !== COOKIE_PREF_VALUE_ALL) {
      return makeCookieBox();
    } else {
      return (url && url.includes("youtube")) ? makeYoutubeVideo() : makeVimeoVideo();
    }
  }

  return(
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      '& div': {
        height: `${calculatedheight}vh !important`,
      }
    }}>
    {getBoxContent()}
    </Box>
  )

}

function CollapsibleCaption(props) {
  const [expanded, setExpanded] = React.useState(true);

  const onClickExpander = () => {
    setExpanded(!expanded);
  }

  return (
    <Box>
      <Grid container sx={{
        border:'none',
        borderTop: (theme) => `1px solid ${theme.palette.primary.main}`,
        paddingTop: (theme) => theme.spacing(1),
        width: props.width || '100%',
        marginLeft: 0,
        marginRight: 'auto',
        marginTop: (theme) => theme.spacing(3),
        alignContent: 'space-around',
        alignItems: 'space-around',
      }}>
        <Grid item
              xs={10}
              onClick={onClickExpander}
              sx={{
                '&:hover' : {
                  cursor: 'pointer',
                }
              }}
        >
        {props.title}
        </Grid>
        <Grid item container justifyContent="flex-end" xs={2}>
        <ExpandMore
          expand={expanded}
          onClick={onClickExpander}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandIcon  align="right" sx={{fontSize: '3rem',}} />
        </ExpandMore>
        </Grid>
    </Grid>
    <Collapse in={expanded}
              collapsedSize={props.collapsedSize || 0}
              sx={{
                marginBottom: (theme) => theme.spacing(3),
              }}>
              
                  <Box sx={{

                  }}>
                    <BhBodyType
                                dangerouslySetInnerHTML={{__html: decode(props.description)}}
                    >
                    </BhBodyType>
                  </Box>
    </Collapse>
    </Box>
  )
}

function WorkDetail (props) {
  return (
    <Box sx={{
      width: '100%',
    }}>
      <Stack spacing={1}>
        {(props.videoUrl && props.videoUrl !== "") ? <VideoItem url={props.videoUrl}/> : <WorkImage src={props.imgSrc} />}
        {props.title && (<Typography variant="h3" sx={{textTransform: 'uppercase'}}>{props.title}</Typography>)}
        {props.description && (<BhBodyType
                                           sx={{
                                             width:['100%','80%'],
                                           }}           
                                >
                                  {props.description}
                                </BhBodyType>)}
      </Stack>
    </Box>
  )
}

export default function WorkPage(props) {
    let params = useParams();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [entries, setEntries] = useState([]);
    

    React.useEffect(() => {
        //prepare the works
        if(props.works && props.works.length > 0) {
          let work = props.works.find((item) => item.slug === params.work);
          console.log("Have work? " + work.content.rendered);
          setTitle(work.title.rendered);
          setDescription(work.content.rendered);
          setEntries(work.patchwork_entries);
        }
    }, [props.works,params.work]);

    const populateWorkDetails = () => {
      if(entries.length > 0) {
        console.log("have entries");
        return entries.map((entry, index) => {
          return (index > 0 && <div key={index}>
                 {(index === 1 && description) && <CollapsibleCaption 
                                                      title={<Typography variant="h3">{decode(title)}</Typography>}
                                                      collapsedSize={0}
                                                      width="100%" 
                                                      description={description}>
                                                      </CollapsibleCaption>}
                  <WorkDetail
                    videoUrl={entry.videoUrl}
                    imgSrc={entry.imageUrl}
                    description={entry.description}
                 />
                 </div>)
        });
      } else {
        return <></>
      }
    }


    return(
      <Stack
        spacing={3}
        sx={{
            width:'100%',
            paddingBottom:(theme) => theme.spacing(6),
            borderBottom: (theme) => `5px ${theme.palette.secondary.main} solid`,
            marginBottom: '-100px',
        }}
        >
          <Fade timeout={800} in={props.works.length > 0}>
            <Stack
              spacing={6}
              sx={{
                width: ['100%','80%'],
                margin: `0 auto 0 auto`,
                marginTop: (theme) => theme.fixedMargins.top,
              }}>
                {populateWorkDetails()}
            </Stack>

          </Fade>
      </Stack>
    )
}